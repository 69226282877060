<template>
  <div class="cancel-donation">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <navigator :items="navigate_items"></navigator>
        </div>
        <div class="col-12">
          <div class="card card-shadow">
            <div class="card-header form-title">{{ titlePage }}</div>
            <div class="card-body">
              <div class="container form-container">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Nome</label>
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="client.name"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>Descrição</label>
                      <span class="required-field">*</span>
                      <textarea
                        v-bind:disabled="formType == 'edit'"
                        cols="30"
                        rows="6"
                        class="form-control"
                        v-model="client.description"
                      ></textarea>
                    </div>
                  </div>
                  <div  v-if="formType == 'create'" class="col-12">
                    <button
                      class="btn btn-primary float-right btn-submit"
                      v-on:click="save()"
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import { bus } from "@/main";
import helper from "@/imports/Helpers";
import validator from "@/imports/validator/validate";
import cancel_validate from "@/imports/validator/models/cancel_donation";

export default {
  name: "cancel-donation",
  props: {
    id: Number,
    type: String,
  },
  components: {},
  data() {
    return {
      client: {},
      formType: "create",
      titlePage: "Novo Cancelamento",
      navigate_items: [
        {
          text: "Clientes",
          route: "dashboard.clients",
        },
        {
          text: "Criar",
          active: true,
        },
      ],
    };
  },
  mounted() {
     if (this.id && this.type == "create") {
      this.formType = "create";
      this.getDataObjectClient(this.id);
    } else {
      this.formType = "edit";
      this.navigate_items[1].text = "Visualizar";
      this.getDataObject(this.id);
    }
  },
  methods: {
     getDataObjectClient: function () {
      bus.$emit("changeLoading", true);
      helper.getDataObject("clients", this.id).then((response) => {
        this.client = response.data;
        bus.$emit("changeLoading", false);
      });
    },
    getDataObject: function () {
      bus.$emit("changeLoading", true);
      helper
        .getDataObject("clients/get_operation", this.id)
        .then((response) => {
          this.client = response.data;
          this.client.name = this.client.client.name;
          this.titlePage = this.client.name;
          bus.$emit("changeLoading", false);
        });
    },
    save: function () {
      let errors = validator.validate(this.client, cancel_validate.model);
      if (errors.length == 0) {
        helper
          .saveCustomDataObject(
            "clients",
            "cancel_donation",
            this.client,
            this.formType,
            "Cancelamento",
            "o"
          )
          .then(() => {
            this.$router.push({ name: "dashboard.clients" });
          });
      } else {
        helper.showErrorsForm(errors);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/general.scss";
</style>